<template>
  <div>
    <div class="title" style="">  人员详情  </div>
     <el-card  style="margin-top:10px">
          <el-form ref="formdata" :model="formdata" label-width="80px">
          <el-row>
              <el-col :span="4">
                  <el-form-item label="姓名">
                    {{formdata.name}}
                </el-form-item>
              </el-col>
              <el-col :span="5">
                  <el-form-item label="身份证号">
                     {{formdata.idCard}}
                 </el-form-item>
              </el-col>
              <el-col :span="5">
                  <el-form-item label="银行卡号">
                     {{formdata.bankNum}}
                </el-form-item>
              </el-col>
              <el-col :span="5">
                  <el-form-item label="手机号">
                     {{formdata.phone}}
                </el-form-item>
              </el-col>
              <el-col :span="5">
                  <el-form-item label="邮箱">
                     {{formdata.email}}
                  </el-form-item>
              </el-col>
          </el-row>
          </el-form>
     </el-card>
     <el-row class="but" style="margin-top:20px">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" >
              <el-tab-pane label="结算记录" name="first" class="tab"></el-tab-pane>
              <el-tab-pane label="任务领取记录" name="second"></el-tab-pane>
              <el-tab-pane label="基本信息" name="third"></el-tab-pane>
        </el-tabs>
    </el-row>
    <div>

    </div>
    <div v-if="activeName=='first'">
      <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>筛选条件</span>
          </div>
           <el-form :model="form"   label-width="100px" class="demo-ruleForm">
                <el-form-item label="任务名称" class="item" >
                     <el-input v-model="form.taskName" style="width:300px" ></el-input>
                 </el-form-item>
                  <el-form-item label="订单号" class="item">
                     <el-input v-model="form.batchNo" style="width:300px" ></el-input>
                 </el-form-item>
                 <!-- <el-form-item label="统一社会信用代码" class="item" label-width="160px">
                     <el-input v-model="form.creditCode" style="width:300px" ></el-input>
                 </el-form-item> -->
                  <el-form-item label="银行账号" class="item" >
                     <el-input v-model="form.bankNum" style="width:300px"></el-input>
                 </el-form-item>
                  <el-form-item label="结算完成时间" class="item"  label-width="160px">
                     <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="form.value1"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                 </el-form-item>
                  <el-form-item label="交易状态" class="item">
                     <el-select v-model="form.transactionStatus" placeholder="请选择" style="width:180px" clearable>
                        <el-option
                            v-for="item in transactionStatusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                  <el-form-item label="完税状态" class="item">
                     <el-select v-model="form.taxPaymentStatus" placeholder="请选择" style="width:180px" clearable>
                        <el-option
                            v-for="item in taxPaymentStatusList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                 <el-button type="primary" style="margin-left:40px;width:100px" @click="querySearch()">查询</el-button> 
                <el-button type="primary" @click="exportlist1()">导出</el-button>
        </el-form>
                                   
      </el-card>
      <el-card style="margin-top:15px">
        <p>每一笔结算的完税日期为结算完成时间的次月10号</p>
        <p>合计结算成功金额  {{amount}}</p>
          <el-table
        v-loading="load"
        :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
        <el-table-column
          header-align="center"
          align="center"
          prop="taskName"
          label="任务名称">
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          prop="taskReceivingTime"
          label="任务领取时间">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="batchNo"
          label="订单号">
        </el-table-column>
        <!-- <el-table-column
        header-align="center"
          align="center"
          prop="creditCode"
        label="统一社会信用代码">
        </el-table-column> -->
        <el-table-column
        header-align="center"
          align="center"
          prop="industryCode"
          label="行业代码">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="bankNum"
          label="银行账号">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="settlementStandard"
          label="结算单价">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="workload"
          label="结算数量">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="amount"
          label="结算金额">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="settlementTime"
          label="结算完成时间">
        </el-table-column>
        <el-table-column
        header-align="center"
          align="center"
          prop="transactionStatus"
          label="交易状态">
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            label="完税状态"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.transactionStatus!='成功'">——</span>
              <span  v-else-if="scope.row.taxPaymentStatus==-1">待完税</span>
              <span  v-else-if="scope.row.taxPaymentStatus==-2" >完税中</span>
              <el-button v-else @click="TaxPayment(scope.row.invoiceFileId)" type="text" >已完税</el-button>
            </template>
          </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="currPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          style="text-align:right;margin-top:10px"
          class="text_center"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </el-card>
    </div>
    <!-- 2页面 -->
    <div v-if="activeName=='second'">
      <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>筛选条件</span>
          </div>
           <el-form :model="screenform"   label-width="100px" class="demo-ruleForm">
                <el-form-item label="任务名称" class="item" >
                     <el-input v-model="screenform.taskName" style="width:300px" ></el-input>
                 </el-form-item>
                 <!-- <el-form-item label="统一社会代码" class="item" label-width="160px">
                     <el-select v-model="screenform.transactionStatus" placeholder="请选择" style="width:260px" clearable>
                        <el-option
                            v-for="item in CreditCodelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item> -->
                 <el-form-item label="领取方式" class="item">
                     <el-select v-model="screenform.Receivingmethod" placeholder="请选择" style="width:200px" clearable>
                        <el-option
                            v-for="item in ReceivingmethodList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                  <el-form-item label="领取时间" class="item"  label-width="160px">
                     <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="screenform.value1"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                 </el-form-item>
                <el-button type="primary" style="margin-left:40px;width:100px" @click="list(1)">查询</el-button> 
        </el-form>
                                   
    </el-card>
    <el-card style="margin-top:15px">
      <p>累计结算金额合计  {{amount1}}</p>
        <el-table
       v-loading="load1"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData1"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="taskName"
        label="任务名称">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="manageRange"
        label="经营范围">
      </el-table-column>
       <!-- <el-table-column
       header-align="center"
        align="center"
        prop="industryCode"
        label="行业代码">
      </el-table-column> -->
      <el-table-column
        header-align="center"
        align="center"
        prop="taskReceiveTime"
        label="任务领取时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="receiveType"
         label="领取方式">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="settlementStandard"
         label="结算标准">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="price"
        label="结算单价">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="settlementCycleName"
        label="结算周期">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="totalAmount"
        label="累计结算金额">
      </el-table-column>
      <!-- <el-table-column
       header-align="center"
        align="center"
        prop="creditCode"
       label="统一社会信用代码">
      </el-table-column> -->
      <el-table-column
       header-align="center"
        align="center"
        prop="status"
        label="账户状态">
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle1"
        @current-change="currentChangeHandle1"
        :current-page="currPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        style="text-align:right;margin-top:10px"
        class="text_center"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-card>
    </div>
    <!-- 3页面 -->
    <div v-if="activeName=='third'">
      <el-card class="thirdClass">
        <el-form ref="form" :model="formThird" label-width="120px">
          <el-form-item label="性别" class="formItem"></el-form-item>
          <el-form-item label="注册时间" class="formItem"></el-form-item>
          <el-form-item label="账号状态" class="formItem"></el-form-item>
          <el-form-item label="实名认证状态" class="formItem"></el-form-item>
        </el-form>
      </el-card>
       <el-card style="margin-top:15px;font-size:14px">
        <div>身份证信息</div>
        <el-row>
          <el-col :span="6">正面</el-col>
           <el-col :span="6">反面</el-col>
        </el-row>
        <el-row>
          <el-col :span="6"><img src="" alt=""></el-col>
          <el-col :span="6"><img src="" alt=""></el-col>
        </el-row>
        <p>签约信息</p>
        <p>签约时间 {{}}</p>
        <p>合同文件</p>
      </el-card>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="80%">
     <iframe :src="url" frameborder="0" style="width:100%;height:600px"></iframe>
    </el-dialog>
  <!-- 2 -->
     
     
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { export2Excel } from '@/utils/util'
import { Detail,successMoney,SettlementList,getNotAuthUrl,getCreditCodeByIdNumber,source,selectTaskRecordByBusinessId} from '@/api/individual'
export default {
   components:{
      pdf
  },
   data() {
    return {
      load:false,
      load1:false,
      url:"",
      dialogVisible:false,
      activeName: 'first',
      transactionStatusList:[{
          value: '90',
          label: '结算中'
        }, {
          value: '91',
          label: '成功'
        }, {
          value: '99',
          label: '失败'
        }],
         taxPaymentStatusList:[{
          value: '1',
          label: '已完税'
        }, {
          value: '-1',
          label: '待完税'
        }, {
          value: '-2',
          label: '完税中'
        }],
      value1:"",
      form:{},
      formdata:{},
      tableData:[],
      tableData1:[],
      pageSize: 10,
      totalPage: 0,
      totalCount: 0,
      currPage: 1,
      pageSize1: 10,
      totalPage1: 0,
      totalCount1: 0,
      currPage1: 1,
      amount:"",//合计结算成功金额
      amount1:"",//累计结算金额合计
      exportList:[],//导出数组
       columns:[
        {
            title:"任务名称",
            key:"taskName"
          },
           {
            title:"任务领取时间",
            key:"taskReceivingTime"
          },
           {
            title:"订单号",
            key:"batchNo"
          },
          //  {
          //   title:"统一社会信用代码",
          //   key:"creditCode"
          // },
           {
            title:"行业代码",
            key:"industryCode"
          },
          {
            title:"银行账号",
            key:"bankNum"
          },
          {
            title:"结算单价",
            key:"settlementStandard"
          },
         
           {
            title:"结算数量",
            key:"workload"
          },
           {
            title:"结算金额",
            key:"amount"
          },
           {
            title:"结算完成时间",
            key:"settlementTime"
          },
           {
            title:"交易状态",
            key:"transactionStatus"
          },
           {
            title:"完税状态",
            key:"taxPaymentStatus"
          },

          ],//导出表格
      screenform:{},
      CreditCodelist:[],      
      formThird:''//基本信息
    }
   },
   mounted(){
    this.a1();
  },
    methods: {
    async  a1(){
      await   Detail({individualBusinessUserId:this.$route.query.Id}).then((data) => {
            if(data && data.code == 200) {
              this.formdata = data.data
               if (data.data.businessId==""||data.data.businessId==null) {
                  this.formdata.businessId=0
                }
                 this.getDataList() 
                 this.successMoneyed()
                //  第二个页面刚进去调用的
                 this.getCreditCode()
            }
        })
       },
          // 查询
    querySearch(){
        this.currPage = 1
        this.successMoneyed() 
        this.getDataList()
    },
    async  successMoneyed(){
        let params = {
            bankNum:this.form.taskName,
            batchNo:this.form.batchNo,
            creditCode:this.form.creditCode,
            bankNum:this.form.bankNum,
            settStartTime:this.form.value1==""||this.form.value1==null ? "" : (this.form.value1[0] + ' 00:00:00 '),
            settEndTime:this.form.value1==""||this.form.value1==null? "" : (this.form.value1[1] + ' 23:59:59 '),
            transactionStatus:this.form.transactionStatus,
            taxPaymentStatus:this.form.taxPaymentStatus,
            businessId:this.formdata.businessId
        }
       await  successMoney(params).then((data) => {
         if(data && data.code == 200) {
                this.amount = data.data.amount
            }
        })
      },
      handleClick(tab, event) {
      //  this.currPage=1
      //  this.pageSize=10
      //   if (tab.index==0)  this.taskState=10031
      //   if (tab.index==1)  this.taskState=""
      //   if (tab.index==2)  this.taskState=10030
      //   if (tab.index==3)  this.taskState=10032
      //     this.getDataList() 
      },
    async  getDataList(){
         this.load=true
        let params = {
            bankNum:this.form.taskName,
            batchNo:this.form.batchNo,
            creditCode:this.form.creditCode,
            bankNum:this.form.bankNum,
            settStartTime:this.form.value1==""||this.form.value1==null ? "" : (this.form.value1[0] + ' 00:00:00 '),
            settEndTime:this.form.value1==""||this.form.value1==null? "" : (this.form.value1[1] + ' 23:59:59 '),
            transactionStatus:this.form.transactionStatus,
            taxPaymentStatus:this.form.taxPaymentStatus,
            current: this.currPage,
            size: this.pageSize,
            businessId:this.formdata.businessId
        }
      await  SettlementList(params).then((data) => {
          this.load = false
            if(data && data.code == 200) {
               this.tableData = data.data.records
                this.totalCount = data.data.total
            } else {
                this.tableData = []
                this.totalCount = ''
            }
        })
      },
      // 导出
      exportlist1(){
         let params = {
            businessId:this.formdata.businessId,
            bankNum:this.form.taskName,
            batchNo:this.form.batchNo,
            creditCode:this.form.creditCode,
            bankNum:this.form.bankNum,
            settStartTime:this.form.value1==""||this.form.value1==null ? "" : (this.form.value1[0] + ' 00:00:00 '),
            settEndTime:this.form.value1==""||this.form.value1==null? "" : (this.form.value1[1] + ' 23:59:59 '),
            transactionStatus:this.form.transactionStatus,
            taxPaymentStatus:this.form.taxPaymentStatus,
            current: 1,
            size:this.totalCount,
        }
        SettlementList(params).then((data) => {
           if(data && data.code == 200) {
               this.exportList = data.data.records
                 this.exportList.map(item=>{
                 if (item.transactionStatus!="成功") {
                   item.taxPaymentStatus="——"
                   return
                }else if (item.taxPaymentStatus==-1) {
                   item.taxPaymentStatus="待完税"
                   return
                }else if (item.taxPaymentStatus==-2) {
                   item.taxPaymentStatus="完税中"
                   return
                }
                else{
                  item.taxPaymentStatus="已完税"
                }
              })
               export2Excel(this.columns,this.exportList,"结算记录")
           }
        })
      },
          // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
    // 2222222
      sizeChangeHandle1(val) {
      this.pageSize1 = val;
      this.currPage1 = 1;
      this.list();
    },
    // 当前页
    currentChangeHandle1(val) {
      this.currPage1 = val;
      this.list();
    },
    TaxPayment(a){
      getNotAuthUrl({fileId:a}).then((data) => {
        if (data&&data.code==200) {
           this.dialogVisible=true
           this.url=data.data.value
        }
      })
    },
    getCreditCode(){
        let data1={
          idNumber:this.formdata.idCard
        }
       getCreditCodeByIdNumber(data1).then((data) => {
            if(data && data.code == 200) {
             this.CreditCodelist=data.data
            }
        })
        //领取方式
        source({type:"BUSINESS_TASK_LOG_APPLY_TYPE"}).then((data) => {
            if(data && data.code == 200) {
             this.ReceivingmethodList=data.data
            }
        })
        this.successMoneyed1()
        this.list()
    },
    async list(a){
       if (a) {  //判断是不是搜索
          this.currPage1=1 
          this.pageSize1=10
        }
      this.load1=true
        let params = {
            taskName:this.screenform.taskName,
            creditCode:this.screenform.creditCode,
            receiveType:this.screenform.Receivingmethod,
            receiveStartTime:this.screenform.value1==""||this.screenform.value1==null ? "" : (this.screenform.value1[0] + ' 00:00:00 '),
            receiveEndTime:this.screenform.value1==""||this.screenform.value1==null? "" : (this.screenform.value1[1] + ' 23:59:59 '),
            current: this.currPage1,
            size: this.pageSize1,
            businessId:this.formdata.businessId
        }
      await  selectTaskRecordByBusinessId(params).then((data) => {
          this.load1 = false
            if(data && data.code == 200) {
               this.tableData1 = data.data.records
                this.totalCount1 = data.data.total
            } else {
                this.tableData1 = []
                this.totalCount1 = ''
            }
        })
    },
    // 合计金额
    async  successMoneyed1(){
        let params = {
            businessId:this.formdata.businessId
        }
       await  successMoney(params).then((data) => {
         if(data && data.code == 200) {
                this.amount1 = data.data.amount
            }
        })
      },
  }
};
</script>

<style scoped lang="scss">
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
    color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
div /deep/.el-tabs__item:hover {
    color:#ffff;
    background-color: #9e99b9;
}
div /deep/.el-tabs__item.is-active {
    color:#ffff;
    background-color: #9e99b9;
}
div /deep/.el-tabs__item{
  width:130px !important;
  text-align: center;
  background: #ffffff;
}
.clearfix span{
     font-size: 15px;
}
.item{
    display: inline-block;
}
.thirdClass{
  .formItem{
    height: 20px !important;
  }
}
</style>