import axios from '@/utils/http'
// -----------------个体工商户管理-------------

// 个体工商户管理列表
export const infoPageList = (params) => {
    return axios.request({
        url: '/individualBusiness/infoPageList',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 来源下拉查询
export const source = ({type}) => {
    return axios.request({
        url: '/merchantTask/getDownBoxByType/' + type,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 签约状态下拉查询
export const contract = ({type}) => {
    return axios.request({
        url: '/merchantTask/getDownBoxByType/' + type,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 获取模板列表
export const templateList = ({}) => {
    return axios.request({
        url: '/sms/template-list',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 获取任务列表
export const taskList = ({}) => {
    return axios.request({
        url: '/sms/task-list',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 临时文件上传
export const tempUpload= ({params}) => {
    return axios.request({
        url:"/file/tempUpload",
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 批量下发短信
export const batchSend = (data1) => {
    return axios.request({
        url: '/sms/batch-send',
        params: data1,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 短信发送记录列表
export const logList = (data1) => {
    return axios.request({
        url: '/sms/log-list',
        data: data1,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 获取下发短信名单模板连接
export const excel = ({}) => {
    return axios.request({
        url: '/sms/excel',
        data: {},
        method: 'get',
        // responseType:'blob',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据id查询个体工商户用户详细信息
export const Detail = ({individualBusinessUserId}) => {
    return axios.request({
        url: '/individualBusiness/selectIndividualBusinessDetailById/'+ individualBusinessUserId,
        data: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 据个体工商户id查询本企业合计结算成功金额
export const successMoney = (params) => {
    return axios.request({
        url: '/individualBusiness/selectBusinessSettlementTotalById',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据个体工商户id查询此用户结算记录
export const SettlementList = (params) => {
    return axios.request({
        url: '/individualBusiness/selectBusinessSettlementListById',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据文件id 获取未授权文件URL
export const getNotAuthUrl = ({fileId}) => {
    return axios.request({
        url: '/file/getNotAuthUrl/'+fileId,
        data: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 得到统一社会信用代码税号,根据用户身份证号码
export const getCreditCodeByIdNumber = (data1) => {
    return axios.request({
        url: '/individualBusiness/getCreditCodeByIdNumber',
        params: data1,
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据企业任务查询个体工商户任务领取记录
export const selectTaskRecordByBusinessId = (params) => {
    return axios.request({
        url: '/individualBusiness/selectTaskRecordByBusinessId',
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}